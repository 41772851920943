// vertical slug constants
export const COFFEE_CLUBS_PATH = "coffee-clubs";
export const PET_FOOD_DELIVERY_PATH = "pet-food-delivery";
export const WINE_CLUBS_PATH = "wine-clubs";
export const MEAT_DELIVERY_SERVICES_PATH = "meat-delivery-services";
export const INCORPORATION_SERVICES_PATH = "incorporation-services";
export const MATTRESSES_PATH = "mattresses";
export const MORTGAGE_PATH = "mortgage";
export const SURVEY_SITES_PATH = "survey-sites";
export const SOLAR_PATH = "solar";
export const BUSINESS_VPN_PATH = "business-vpn";
export const PERSONAL_VPN_PATH = "personal-vpn";
export const MATTRESS_TOPPERS_PATH = "mattress-toppers";
export const ELECTRONIC_SIGNATURE_PATH = "electronic-signature";
export const PROMOTIONAL_PRODUCTS_PATH = "promotional-products";
export const ACCOUNTS_PAYABLE_PATH = "accounts-payable";
export const BUSINESS_PASSWORD_MANAGERS_PATH = "business-password-managers";
export const GLOBAL_HR_PATH = "global-hr";
export const WEBSITE_BUILDERS_PATH = "website-builders";
export const NONSTICK_COOKWARE_PATH = "non-stick-cookware";
export const TEA_CLUBS_PATH = "tea-clubs";
export const PDF_EDITORS_PATH = "pdf-editors";
export const BACKGROUND_SEARCH_PATH = "background-search";
export const LANGUAGE_LEARNING_PATH = "language-learning";
export const DIET_MEAL_PLANS_PATH = "diet-meal-plans";
export const MEAL_DELIVERY_PATH = "meal-delivery";
export const HOME_WARRANTIES_PATH = "home-warranties";
export const DNA_TESTING_PATH = "dna-testing";
export const ELECTRIC_BIKES_PATH = "electric-bikes";
export const FLOWER_DELIVERY_PATH = "flower-delivery";
export const STREAMING_SERVICES_PATH = "streaming-services";
export const CELL_PHONE_PLANS_PATH = "cell-phone-plans";
export const INVISIBLE_BRACES_PATH = "invisible-braces";
export const HAIR_GROWTH_PATH = "hair-growth";
export const TEETH_WHITENING_PATH = "teeth-whitening";
export const COSMETICS_PATH = "cosmetics";

// filtering constants
export const MORTGAGE_TYPE_FILTERING_KEY = "mortgageType";
export const LANGUAGE_FILTERING_KEY = "language";
export const HAIR_GROWTH_FILTERING_KEY = "gender";

export const FAVY_SITE_CONFIGURATION = {
	[COFFEE_CLUBS_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[PET_FOOD_DELIVERY_PATH]: {
		useCouponInsteadOfReviewCta: true,
		reviewWidgets: {
			widget: "webReview",
		},
		companyListHeaderParts: [
			"#",
			"Service",
			"Offerings",
			"Human-grade Ingredients?",
			"Free Shipping?",
		],
		serviceRightColumns: [
			{ fields: "offerings", label: "Offerings" },
			{ fields: "humanGradeIngredients", label: "Human-grade Ingredients?" },
			{ fields: "freeShipping", label: "Free Shipping?" },
		],
	},
	[WINE_CLUBS_PATH]: {
		preventRenderingCompanyListHeader: true,
		useCouponInsteadOfReviewCta: true,
		showFeaturedCompany: true,
		reviewWidgets: {
			widget: "all",
			trustpilotWhitelistThreshold: 4.5,
		},
		companyListHeaderParts: [
			"#",
			"Club",
			"Starting Price",
			"Minimum Bottles Per Month",
			"Free shipping?",
		],
		serviceRightColumns: [
			{
				fields: "pricing",
				label: "Starting Price",
				formatter: (pricing) => `$${pricing}`,
			},
			{ fields: "minimumBottlesPerMonth", label: "Minimum Bottles Per Month" },
			{ fields: "freeShipping", label: "Free Shipping?" },
		],
	},
	[MEAT_DELIVERY_SERVICES_PATH]: {
		companyListHeaderParts: [
			"#",
			"Service",
			"Offerings",
			"Ordering Options",
			"Free Shipping?",
		],
		serviceRightColumns: [
			{ fields: "offerings", label: "Offerings" },
			{ fields: "orderingOptions", label: "Ordering Options" },
			{ fields: "freeShipping", label: "Free Shipping?" },
		],
	},
	[INCORPORATION_SERVICES_PATH]: {
		usesSimplifiedDesign: true,
		usesProductDescription: true,
		reviewWidgets: {
			widget: "webReview",
			webReviewDesign: "newNonSoftware",
		},
		showFeaturedCompany: true,
	},
	[MATTRESSES_PATH]: {
		reviewWidgets: {
			widget: "trustpilot",
		},
		companyListHeaderParts: [
			"#",
			"Mattress Company",
			"Year Founded",
			"Trial Period",
		],
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "trialPeriod", label: "Trial Period" },
		],
	},
	[MORTGAGE_PATH]: {
		usesMultiStepModal: true,
		filteringInformation: {
			desktopFilteringButtonSwitch: [
				{
					title: "Mortgage Type",
					filteringKey: MORTGAGE_TYPE_FILTERING_KEY,
					options: [
						{ value: "purchase", label: "Purchase" },
						{ value: "refinance", label: "Refinance" },
					],
				},
			],
			placeholders: {
				DEFAULT: {
					[MORTGAGE_TYPE_FILTERING_KEY]: "Purchase",
				},
			},
		},
		isTargetedPathFilteringAllowed: true,
		isNonTargetedPathFilteringAllowed: true,
		preventRenderingColumns: true,
		preventRenderingCompanyListHeader: true,
		reviewWidgets: {
			widget: "trustpilot",
		},
		companyListHeaderParts: [
			"#",
			"Mattress Company",
			"Year Founded",
			"Trial Period",
		],
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "trialPeriod", label: "Trial Period" },
		],
	},
	[SURVEY_SITES_PATH]: {
		reviewWidgets: {
			widget: "trustpilot",
		},
		companyListHeaderParts: [
			"#",
			"Site",
			"Year Founded",
			"BBB Rating",
			"Payout",
		],
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "bbbRating", label: "BBB Rating" },
			{ fields: "payout", label: "Payout" },
		],
	},
	[SOLAR_PATH]: {
		usesRevampedPhoneLink: true,
		preventRenderingReviewLink: true,
		companyListHeaderParts: ["#", "Company", "Year Founded", "BBB Rating"],
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "bbbRating", label: "BBB Rating" },
		],
	},
	[BUSINESS_VPN_PATH]: {
		usesSoftwareDesign: true,
		usesNewSoftwareDesign: true,
		usesProductDescription: true,
		useCompanyNameForVisitLink: true,
		reviewWidgets: {
			widget: "webReview",
		},
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "pricing", label: "Starting Price (monthly)" },
			{ fields: "minimumUsers", label: "Minimum Users" },
		],
	},
	[PERSONAL_VPN_PATH]: {
		usesSoftwareDesign: true,
		reviewWidgets: {
			widget: "webReview",
		},
		serviceRightColumns: [
			{ fields: "pricing", label: "Starting Price (monthly)" },
			{ fields: "entertainment", label: "Entertainment" },
			{ fields: "privacy", label: "Privacy" },
		],
	},
	[MATTRESS_TOPPERS_PATH]: {
		companyListHeaderParts: ["#", "Company", "Year Founded", "Trial Period"],
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "trialPeriod", label: "Trial Period" },
		],
	},
	[ELECTRONIC_SIGNATURE_PATH]: {
		usesSoftwareDesign: true,
		serviceRightColumns: [
			{ fields: "trialPeriod", label: "Trial Period" },
			{ fields: "pricing", label: "Starting Price" },
			{ fields: "greatFor", label: "Great For" },
		],
	},
	[PROMOTIONAL_PRODUCTS_PATH]: {
		companyListHeaderParts: [
			"#",
			"Company",
			"Year Founded",
			"Bulk Discounts",
			"Free Samples",
		],
		reviewWidgets: {
			widget: "webReview",
		},
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "bulkDiscounts", label: "Bulk Discounts" },
			{ fields: "freeSamples", label: "Free Samples" },
		],
	},
	[ACCOUNTS_PAYABLE_PATH]: {
		usesSoftwareDesign: true,
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "bbbRating", label: "BBB Rating" },
			{ fields: "greatFor", label: "Great For" },
		],
	},
	[BUSINESS_PASSWORD_MANAGERS_PATH]: {
		reviewWidgets: {
			widget: "trustpilot",
		},
		usesSoftwareDesign: true,
		companyListHeaderParts: ["#", "Service", "Year Founded", "Starting Price"],
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "pricing", label: "Starting Price (monthly)" },
			{ fields: "minimumUsers", label: "Minimum Users" },
		],
	},
	[GLOBAL_HR_PATH]: {
		reviewWidgets: {
			widget: "trustpilot",
		},
		usesSoftwareDesign: true,
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "greatFor", label: "Great For" },
		],
	},
	[WEBSITE_BUILDERS_PATH]: {
		reviewWidgets: {
			widget: "webReview",
		},
		usesSoftwareDesign: true,
		usesProductDescription: true,
		preventRenderingSoftwareReviewLink: true,
		useCompanyNameForVisitLink: true,
		serviceRightColumns: [
			{ fields: "freeTrial", label: "Free Trial?" },
			{ fields: "pricing", label: "Starting Price (monthly)" },
			{ fields: "freeDomain", label: "Free Domain?" },
		],
	},
	[NONSTICK_COOKWARE_PATH]: {
		preventRenderingReviewLink: true,
		usesImageGallery: true,
		reviewWidgets: {
			widget: "webReview",
		},
		useCompanyNameForVisitLink: true,
		usesProductDescription: true,
		preventRenderingCompanyListHeader: true,
		serviceRightColumns: [
			{ fields: "warranty", label: "Warranty" },
			{ fields: "freeShipping", label: "Free Shipping?" },
			{ fields: "riskFreeTrial", label: "Risk Free Trial?" },
		],
	},
	[TEA_CLUBS_PATH]: {
		reviewWidgets: {
			widget: "webReview",
		},
		companyListHeaderParts: [
			"#",
			"Club",
			"Pricing",
			"Total Cups",
			"Specialty Grade?",
		],
		serviceRightColumns: [
			{
				fields: "pricing",
				label: "Pricing",
				formatter: (pricing) => `$${pricing}`,
			},
			{ fields: "totalCups", label: "Total Cups" },
			{ fields: "specialtyGrade", label: "Specialty Grade?" },
		],
	},
	[PDF_EDITORS_PATH]: {
		usesSoftwareDesign: true,
		usesNewSoftwareDesign: true,
		usesProductDescription: true,
		reviewWidgets: {
			widget: "webReview",
		},
		serviceRightColumns: [
			{
				fields: "pricing",
				label: "Annual Pricing",
				formatter: (pricing) => `$${pricing}`,
			},
			{ fields: "usersPerAccount", label: "Users Per Account" },
			{ fields: "freeVersion", label: "Free Version?" },
		],
	},
	[BACKGROUND_SEARCH_PATH]: {
		showFeaturedCompany: true,
		reviewWidgets: {
			widget: "webReview",
		},
		usesProductDescription: true,
		usesNewSoftwareDesign: true,
		usesSoftwareDesign: true,
		serviceRightColumns: [
			{ fields: "pricing", label: "Pricing" },
			{ fields: "bestFor", label: "Best For" },
		],
	},
	[LANGUAGE_LEARNING_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[DIET_MEAL_PLANS_PATH]: {
		useCouponInsteadOfReviewCta: true,
		usesSoftwareDesign: true,
		usesNewSoftwareDesign: true,
		usesProductDescription: true,
		preventRenderingSoftwareColumns: true,
		useCompanyNameForVisitLink: true,
		reviewWidgets: {
			widget: "webReview",
		},
	},
	[MEAL_DELIVERY_PATH]: {
		useCouponInsteadOfReviewCta: true,
		usesSoftwareDesign: true,
		usesNewSoftwareDesign: true,
		usesProductDescription: true,
		preventRenderingSoftwareColumns: true,
		useCompanyNameForVisitLink: true,
		reviewWidgets: {
			widget: "webReview",
		},
	},
	[HOME_WARRANTIES_PATH]: {
		useCouponInsteadOfReviewCta: true,
		usesSoftwareDesign: true,
		usesNewSoftwareDesign: true,
		usesProductDescription: true,
		preventRenderingSoftwareColumns: true,
		reviewWidgets: {
			widget: "webReview",
		},
	},
	[DNA_TESTING_PATH]: {
		useCouponInsteadOfReviewCta: true,
		usesSoftwareDesign: true,
		usesNewSoftwareDesign: true,
		usesProductDescription: true,
		preventRenderingSoftwareColumns: true,
		reviewWidgets: {
			widget: "webReview",
		},
	},
	[ELECTRIC_BIKES_PATH]: {
		preventRenderingColumns: true,
		preventRenderingCompanyListHeader: true,
		isProductListingPage: true,
		preventRenderingReviewLink: true,
		usesImageGallery: true,
	},
	[FLOWER_DELIVERY_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[STREAMING_SERVICES_PATH]: {
		useCouponInsteadOfReviewCta: true,
		usesProductDescription: true,
		preventRenderingCompanyListHeader: true,
		serviceRightColumns: [
			{ fields: "startingPrice", label: "Starting Price" },
			{ fields: "freeTrial", label: "Free Trial?" },
		],
		reviewWidgets: {
			widget: "webReview",
			webReviewDesign: "newNonSoftware",
		},
	},
	[CELL_PHONE_PLANS_PATH]: {
		useCouponInsteadOfReviewCta: true,
		usesProductDescription: true,
		preventRenderingCompanyListHeader: true,
		useCompanyNameForVisitLink: true,
		serviceRightColumns: [
			{ fields: "startingPrice", label: "Starting Price" },
			{ fields: "unlimitedDataPrice", label: "Unlimited Data Price" },
			{ fields: "paymentOptions", label: "Payment Options" },
		],
	},
	[INVISIBLE_BRACES_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[HAIR_GROWTH_PATH]: {
		preventRenderingColumns: true,
		preventRenderingCompanyListHeader: true,
		isTargetedPathFilteringAllowed: true,
		isNonTargetedPathFilteringAllowed: true,
		showFeaturedCompany: true,
		filteringInformation: {
			desktopFilteringButtonSwitch: [
				{
					title: "Gender",
					filteringKey: HAIR_GROWTH_FILTERING_KEY,
					options: [
						{ value: "men", label: "Men" },
						{ value: "women", label: "Women" },
					],
				},
			],
		},
	},
	[TEETH_WHITENING_PATH]: {
		preventRenderingColumns: true,
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
	[COSMETICS_PATH]: {
		preventRenderingColumns: true,
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
};
